import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../components/seo";
import Skel from "../components/skel";

const LiveTapLists = () => (
  <Skel>
    <SEO title="Build live tap lists with Taplist.io" />
    <section
      className="section bg-center bg-cover effect-section"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <div className="mask theme-bg opacity-8"></div>
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="white-color h1 m-20px-b">
              Build Live Tap Lists with Taplist.io
            </h2>
          </Col>
        </Row>
      </Container>
    </section>
    <main>
      <section className="section">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3>What are live tap lists?</h3>
            <p>
              Live tap lists are a great way to drive more sales and spend less
              time managing your menus. With a live tap list product like
              Taplist.io, you can instantly publish your menu to web, download
              it as a printable PDF, show a QR code, and even display your menu
              on a TV.
            </p>
            <p>
              Taplist.io is the most trusted, easiest-to-use solution for live
              tap lists. Using Taplist.io, you can can build a beverage menu and
              publish it in minutes.
            </p>

            <h3>What makes Taplist.io a great choice for live tap lists?</h3>
            <p>
              Here are some of the biggest reasons Taplist.io is one of the most
              popular choices among great bars, breweries, and restaurants for
              live tap lists:
            </p>

            <h5>
              <i className="fas fa-check"></i> Transparent, straightforward,
              no-contract pricing
            </h5>
            <p>
              At Taplist.io, we offer transparent and upfront pricing &mdash;
              it's all right there on <a href="/pricing">our pricing page</a>.
              There are no hidden fees, and no salesman calls. And for peace of
              mind, our <a href="/refund-policy">refund policy</a> guarantees
              that if you get started with us and aren't satisfied, we'll make
              it right.
            </p>

            <h5>
              <i className="fas fa-check"></i> Private, instantly-updating
              beverage database
            </h5>
            <p>
              Using Taplist.io, every customer gets their own private database
              for building and managing live tap lists.
            </p>
            <p>
              You're always in <b>complete control</b> of your beverage
              database: Beverage names, descriptions, and artwork, are all set
              and managed by you &mdash; and{" "}
              <b>updates are published instantly</b>. Many craft breweries
              choose us because of this powerful flexibility: After working so
              hard to produce a brew, who wants to use <em>someone else's</em>{" "}
              description or rating?
            </p>

            <h5>
              <i className="fas fa-check"></i> Cloud management dashboard
            </h5>
            <p>
              Taplist.io is managed entirely through a rich web-based{" "}
              <a href="https://app.taplist.io">management dashboard</a>. This
              universal dashboard works equally well on mobile and desktop. It
              can be used from iOS, Android, Windows, or Mac, with no additional
              software. You can add (and remove) staff accounts so they can
              manage your menu here, too.
            </p>

            <h5>
              <i className="fas fa-check"></i> Clean, customizable designs that
              don't require a designer
            </h5>
            <p>
              With Taplist.io, you can build a live tap list which looks as
              unique as your business. Because Taplist.io is newer, our menu
              templates are clean and highly customizable. You can choose from
              several built-in themes and presets, and customize many more
              aspects of your menu, all without needing to call or consult a
              designer.
            </p>

            <h5>
              <i className="fas fa-check"></i> Uses low cost, widely-available
              hardware
            </h5>
            <p>
              When it comes to hardware compatibility, we are the innovators in
              this space. Taplist.io{" "}
              <b>
                works with the most popular, widely available hardware devices
              </b>{" "}
              on the planet: The Amazon FireTV Stick, and Apple TV. We did this
              so you can <b>easily set up, expand, or replace</b> your menu
              hardware.
            </p>

            <h3>It's Easy To Give Taplist.io a Try</h3>
            <p>
              We know that this is an important decision, and you shouldn't have
              to <em>hope</em> that a product is good before purchasing it.{" "}
              <b>Don't take our word for it:</b> We designed Taplist.io to be
              simple to try before buying; take it for a free trial spin.
            </p>

            <p>
              You can create an account, customize your menu, and explore all
              features before buying. Just visit our{" "}
              <a href="/signup">signup page</a> to get started.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <hr />
            <em>Last Updated: November 2022</em>
            <br />
            <em>
              Questions, corrections, or need clarifications? Please{" "}
              <a href="mailto:support@taplist.io">contact us</a>!
            </em>
          </Col>
        </Row>
      </section>
    </main>
  </Skel>
);

export default LiveTapLists;
